<template>
    <div class="accountInfo">
       <div class="account_box">
            <img class="account_img" :src="member.avatar || '../../../assets/images/user.svg'" />
            <div class="account_txt">
                <p>{{ member.userName }}</p>
                <span>当前版本 <i class="tag_free">免费试用</i></span>
            </div>
        </div>
        <div class="account_info">
            <div class="a_title">个人资料</div>
            <ul class="a_list">
                <li>
                    <span>性别：</span><strong>{{ member.iGender }}</strong>
                </li>
                <li>
                    <span>地区：</span><strong>{{ member.iCity }}</strong>
                </li>
                <li>
                    <span>个人简介：</span><strong>{{ member.sProfile }}</strong>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            member: {
                avatar: '',
                userName: '1212',
                iGender: '未知',
                iCity: '未知',
                sProfile: '',
            },
        }
    },
    created() {
        const stAccountInfo = JSON.parse(localStorage.getItem("loginInfo")).data.stAccountInfo
        this.member = {
            avatar: stAccountInfo.sFaceUrl,
            userName: stAccountInfo.sUserName,
            iGender: stAccountInfo.iGender === 1 ? '男' : '女',
            iCity: stAccountInfo.sProvince ? `${stAccountInfo.sProvince} - ${stAccountInfo.sCity}` : '未知',
            sProfile: stAccountInfo.sProfile,
        }
    },
}
</script>
<style lang="less" scoped>
.accountInfo {
    height: 100%;
    display: flex;
    flex-direction: column;
    .account_box {
        background    : #fff;
        padding       : 30px;
        margin-bottom : 20px;
        margin-top: 20px;
        display       : flex;
        flex-direction: row;
        align-items   : center;

        .account_img {
            width        : 80px;
            height       : 80px;
            border-radius: 5px;
            margin-right : 18px;
        }

        .account_txt {
            p {
                font-size    : 20px;
                margin-bottom: 9px;
            }

            span {
                font-size: 12px;
                color    : #9A9EA7;
            }

            i.tag_free {
                border       : 1px #16A83D solid;
                line-height  : 1.2;
                padding      : 2px 7px;
                border-radius: 4px;
                color        : #16A83D;
                margin-left  : 6px;
            }
        }
    }
}
.account_info {
    flex: 1;
    background   : #fff;
    padding      : 30px;
    // margin-bottom: 30px;

    .a_title {
        font-size  : 16px;
        font-weight: bold;
        padding    : 0 0 30px;
    }

    .a_list {
        padding-top: 30px;
        border-top : 1px #D9DCE6 solid;
        font-size  : 14px;

        li {
            margin-bottom: 30px;

            span {
                color  : #9093A0;
                display: inline-block;
                width  : 100px;
            }

            strong {
                font-weight: normal;
            }
        }
    }
}
</style>